<template>
    <MainLayout>
        <template #content>
            <div class="content-container flex flex-1 justify-center items-center flex-col w-full pt-30 pb-20">
                <div class="container h-72">
                    <div class="text-center font-bold text-5xl font-display mb-5">
                        Danke!
                    </div>
                    <div class="text-center text-3xl font-display">
                        Der Gutschein und die Rechnung sind via Email am Weg zu dir. 
                    </div>
                </div>
            </div>
        </template>
    </MainLayout>
</template>
<script>
import MainLayout from '_layouts/index';

export default {
    
    name: 'GuestRedirect',
    components: {
        MainLayout,
      },
    data() {
        return {
            giftPage: false
        }
    },
}
</script>
  